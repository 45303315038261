import { IframeMessage } from '../types/iframe-message';
export function emitIframeMessage(type, payload = {}) {
  const messageData = {
    type,
    ...payload
  };
  console.log('emit iframe message', messageData);
  window.parent.postMessage(messageData, '*');
  if (type === IframeMessage.UpdateHeight && payload && payload.hasOwnProperty('heightInPx')) {
    // for legacy client compatibility
    console.log('emit iframe legacy message', `starter-app:height#${payload.heightInPx}`);
    window.parent.postMessage(`starter-app:height#${payload.heightInPx}`, '*');
  }
}